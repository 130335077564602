import MuiLink from '@material-ui/core/Link';
import Link from 'next/link';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import clsx from 'clsx';
import {useRouter} from 'next/router';

import {isAllowedToUser} from 'src/core/utils';
import {extendedTypography} from 'src/themes/themeNext/extendedTypography';

export const useStyles = makeStyles(theme => ({
  menuItems: {
    display: 'flex',
    gap: theme.spacing(2.5),

    [theme.breakpoints.down('md')]: {
      gap: theme.spacing(2)
    }
  },
  menuItem: {
    position: 'relative',
    // textAlign: 'center',
    textTransform: 'uppercase',
    ...extendedTypography.h5Mobile,
    color: theme.palette.common.white,

    '&::before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: 1,
      bottom: -3,
      left: 0,
      backgroundColor: theme.palette.secondary.main,
      transform: 'scaleX(0)',
      transition: 'transform 0.3s ease'
    },

    '&:hover, &.active': {
      color: theme.palette.secondary.main,
      transition: 'color 0.3s ease'
    },

    '&:hover::before, &.active::before': {
      transform: 'scaleX(1)'
    }
  },
  link: {
    '&:hover': {
      opacity: 1,
      textDecoration: 'none'
    }
  }
}));

export const DesktopMenuItems = ({menu, customer}) => {
  const classes = useStyles();
  const router = useRouter();

  return (
    <div className={classes.menuItems}>
      {menu?.items?.map(item => {
        if (!isAllowedToUser(item, customer)) {
          return null;
        }

        const href = item.page ? `/${item.page.slug}` : item.url;

        return (
          <MuiLink
            passHref
            component={Link}
            key={item.name}
            href={href || '#'}
            classes={{root: classes.link}}
            target={item.name === 'In-Game Services' ? '_blank' : '_self'}
          >
            <Typography
              className={clsx(
                classes.menuItem,
                router.asPath === href && 'active'
              )}
            >
              {item.name}
            </Typography>
          </MuiLink>
        );
      })}
    </div>
  );
};
